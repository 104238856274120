<template>
  <div class="test_tip_content_sty">
    <div class="test_info_sty">
      <p>
        <span> 试卷名称 </span>
        {{ testPaperContent.title }}
      </p>
      <p>
        <span> 考试时间 </span>
        {{formatDate(testPaperContent.startTime, "MM-DD HH:mm")}} ~ {{formatDate(testPaperContent.endTime, "MM-DD HH:mm")}}
      </p>
      <p>
        <span> 答题时长 </span>
        {{ testPaperContent.duration }}分钟
      </p>
      <p>
        <span> 重考次数 </span>
        还剩{{ testPaperContent.redoMaxCount - testPaperContent.testCount + 1 }}次
      </p>
    </div>
    <div class="test_tip_content_num_sty">
      <p>
        本试卷共包含<span>{{ totleNum }}</span
        >道题目,
      </p>
      <p>
        其中单选题<span>{{ testPaperContent.radioCount }}</span
        >，多选题<span>{{ testPaperContent.checkboxCount }}</span
        >道，判断题<span>{{ testPaperContent.booleanCount }}</span
        >,填空题<span>{{ testPaperContent.supplyCount }}</span
        >道,简答题<span>{{ testPaperContent.answerCount }}</span
        >道
      </p>
    </div>
    <div class="test_explain_sty">
      <div>
        <i class="iconfont icon_col">&#xe615;</i><span>考试说明：</span>
        <p>1、请不要中途离开考试界面，离开或退出考试界面会继续计时,并且倒计时结束后本次考试为0分。</p>
        <p>2、请检查电脑设备的电量及网络情况，确保考试的顺利进行。</p>
        <p>
          3、考试时间截止或答题时间结束之前请尽快完成考试并点击提交，避免影响本次考试分数。
        </p>
        <p>
          4、考试成绩以<span style="color: red">{{
            testPaperContent.redoScoreType == 1
              ? "最后一次考试成绩"
              : "最高分数"
          }}</span
          >为准，请确认是否重考
        </p>
      </div>
    </div>
    <div class="sure_btn_sty">
      <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
    </div>
    <div class="btn_conten_sty">
      <div @click="close">取消</div>
      <div :class="[{ checked_sty: checked }]" @click="goExamination">
        立即重考
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/index";
export default {
  data() {
    return {
      checked: false,
      formatDate,
    };
  },
  props: {
    testPaperContent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totleNum: function () {
      return (
        this.testPaperContent.radioCount +
        this.testPaperContent.checkboxCount +
        this.testPaperContent.booleanCount +
        this.testPaperContent.supplyCount +
        this.testPaperContent.answerCount
      );
    },
  },
  created() {
    
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    // 前往考试
    goExamination() {
      if (this.checked) {
        this.$router.push(
          `/myStudy?option=3&path=examination&testId=${this.testPaperContent.testPaperId}`
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test_tip_content_sty {
  width: 100%;
  .test_tip_content_num_sty {
    width: 100%;
    text-align: left;
    font-style: 14px;
    margin-top: 20px;
    span {
      color: #0d79ff;
    }
  }
  .test_info_sty {
    padding: 23px;
    padding-left: 0;
    padding-top: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f2f2;
    span {
      margin-right: 15px;
      color: #999;
    }
  }
  .test_explain_sty {
    margin-top: 28px;
    background: #f6f6f6;
    padding: 17px;
    box-sizing: border-box;
    margin-bottom: 15px;
    p {
      margin-top: 10px;
    }
  }
  .btn_conten_sty {
    width: 100%;
    text-align: right;
    & > div {
      display: inline-block;
      width: 96px;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      border-radius: 40px;
      margin-top: 34px;
      cursor: pointer;
      &:first-child {
        border: 1px solid #e7e7e7;
      }
      &:nth-child(2) {
        border: 1px solid #0d79ff;
        background: #0d79ff;
        color: #ffffff;
        margin-left: 14px;
        opacity: 0.5;
      }
    }
  }
  .icon_col {
    color: #0d79ff;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }
}
.checked_sty {
  opacity: 1 !important;
}
</style>