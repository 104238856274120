import { mapActions } from 'vuex'
export default {
    data() {
        return {
            currentTime:null,
        }
    },
    created() {
        this.getCurrentTimeAsync()
    },
    methods:{
        // 同步获取服务器时间
        async getCurrentTimeAsync() {
            this.currentTime = await this.getCurrentTime();
        },
        ...mapActions(['getCurrentTime'])
    }
}